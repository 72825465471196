//REACTJS
import React, { useEffect, useState } from 'react';
//GATSBYJS
import { navigate } from 'gatsby';
//FORMIK & YUP
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
//APPSYNC-MUTATIONS
import { handleCreatePost } from '../appsync_functions/mutations';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
//CUSTOM-HOOK
import useGeoLocation from '../hook/useGeoLocation';

// ICONS
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

// Toast
import { Toaster, toast } from 'react-hot-toast';

// Helmet 
import Helmet from "react-helmet";

export default function CreatePost() {
    const [{ username, isSignedin, role }] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState([])

    const initialValues = {
        title: "",
        description: "",
        massageType: []
    }


    const validationSchema = Yup.object().shape({
        title: Yup
            .string()
            .required('Title is required'),
        description: Yup
            .string()
            .required('Description is required'),
        massageType: Yup
            .array()
            .of(Yup.string())
            .required('Massages are required')
            .min(1, 'At least one massage type is required'),
    });


    const { latitude, longitude, location, city, country } = useGeoLocation();


    const onSubmit = async (values, { setSubmitting }) => {
        console.log("data ==>> ", values);
        setLoading(true)
        try {
            await handleCreatePost({ ...values, author: username, latitude: latitude?.toString(), longitude: longitude?.toString(), location, city, country });
            setSubmitting(false);
            toast.success('Post created successfully', {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
            navigate("/");
        }
        catch (error) {
            console.log('error username:', error);
            toast.error(error.message.toString(), {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        if (!isSignedin || role !== 'business') {
            navigate("/");
        }
    }, [isSignedin]);


    if (!isSignedin || role !== 'business') return <></>;

    let massages = [
        "Thai Massage",
        "Swedish Massage",
        "Chinese Massage",
        "Shiatsu Massage",
        "Balinese Massage",
        "Ayurvedic Massage",
        "Deep Tissue Massage",
        "Sports Massage",
        "Hot Stone Massage",
        "Aromatherapy Massage",
        "Other"
    ]

    // const handleChange = (val) => {
    //     if (!selected.includes(val)) {
    //         setSelected([...selected, val])
    //     }
    //     else {
    //         setSelected(selected.filter((massage) => massage !== val))
    //     }
    // }

    return (
        <div className='flex justify-center font-poppins'>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Toaster />
            <div className='w-full md:w-[600px] min-h-[100dvh] flex flex-col px-5 pt-7'>
                <div className='flex gap-5 items-center'>
                    <div onClick={() => navigate("/")} className='w-[25px] h-[25px] bg-gray-300 rounded-full flex justify-center items-center'>
                        <MdKeyboardArrowLeft className='font-bold text-sm' />
                    </div>
                    <h2 className='text-zinc-800 font-semibold tracking-wide'>Create Post</h2>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, values }) => (
                        <Form className="rounded-md flex-grow flex flex-col gap-5 mt-10">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="title" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Title
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4'>
                                    <Field
                                        type="text"
                                        name="title"
                                        id="title"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the title"
                                    />
                                </div>
                                <ErrorMessage name="title" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="description" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Description
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4'>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        id="description"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs resize-none"
                                        placeholder="Enter the description"
                                        cols="2"
                                        rows="4"
                                    />
                                </div>
                                <ErrorMessage name="description" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="description" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Massage Types
                                </label>
                                <FieldArray name="massageType">
                                    {({ push, remove }) => (
                                        <div className='flex gap-2 flex-wrap'>
                                            {
                                                massages.map((val, i) => {
                                                    const isSelected = values.massageType.includes(val);
                                                    return (
                                                        <span
                                                            key={i}
                                                            className={`text-sm font-medium cursor-pointer ${isSelected ? 'text-white bg-gradient-to-r from-[#EF7996] to-[#6670F4]' : 'text-black bg-[#F4F5F7]'} py-[7px] px-3 rounded-full`}
                                                            onClick={() => {
                                                                if (isSelected) {
                                                                    const idx = values.massageType.indexOf(val);
                                                                    remove(idx);
                                                                } else {
                                                                    push(val);
                                                                }
                                                            }}
                                                        >
                                                            {val}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>
                                    )}
                                </FieldArray>
                                <ErrorMessage name="massageType" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className='flex justify-center py-10'>
                                <button
                                    type="submit"
                                    className="bg-primary text-white font-medium p-2 pl-4 flex justify-between items-center rounded-full w-[150px] text-center text-sm"
                                    disabled={isSubmitting}
                                >
                                    {
                                        loading ? 'loading ...' :
                                            'Create Item'
                                    }
                                    <div className='w-[30px] h-[30px] flex justify-center items-center rounded-full bg-[#556FFF]'>
                                        <MdOutlineKeyboardArrowRight className='font-bold' />
                                    </div>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}


export function Head() {
    return (
        <title> Create | MassageOpen </title>
    )
}